import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import Layout from '../layouts/layout'
import TitleBar from '../components/title-bar'
import '../styles/about.scss'

const About = ({ location }) => {
  const data = useStaticQuery(graphql`
    query IconQuery {
      icon: file(absolutePath: { regex: "/daseveny-icon.png/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 150, height: 150)
        }
      }
    }
  `)

  return (
    <Layout location={location} title="About">
      <SEO title="About" description={'What is Daseveny?'} />
      <div
        className="flex flex-wrap flex-col min-h-full justify-start items-start shadow-md pt-4 md:pt-10 px-2 md:px-10 pb-1 md:pb-4 mb-1 md:mb-2"
        style={{
          color: 'var(--textNormal)',
          backgroundColor: 'var(--bg-card)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
          fontSize: '1rem',
          lineHeight: 1.6
        }}
      >
        <TitleBar
          to={location.pathname}
          title="អំពីដាសេវេនី"
          location={location}
        />

        <div className="flex w-full justify-center pt-8 pb-8">
          <GatsbyImage
            className="daseveny-logo"
            image={getImage(data.icon)}
            alt="Daseveny Icon"
            style={{
              marginRight: 0,
              marginBottom: 0,
              minWidth: 80,
              borderRadius: `100%`
            }}
            imgStyle={{
              borderRadius: `50%`
            }}
          />
        </div>
        <div className="flex w-full justify-center text-center capitalize font-black text-6xl pb-5">
          ដាសេវេនី
        </div>
        <div className="flex w-full justify-center text-center capitalize font-black text-4xl pb-12">
          DASEVENY
        </div>

        <div className="text-lg font-bold mb-8 px-2">
          Happy coding
          <span className="ml-1" role="img" aria-label="heart">
            &#x270C;
          </span>
        </div>

        <div className="mb-6 leading-loose px-2">
          DASEVENY ជាវែបសាយប្លក់ចែករំលេកចំណេះដឹង បទពិសោធន៍អំពីបច្ចេកវិទ្យា
          ប្រូក្រាមមីង និងចំណេះដឹងទូទៅផ្សេងៗ។
        </div>

        <div className="font-bold text-lg pb-4 px-2">អំពីខ្ញុំ</div>
        <div className="mb-6 leading-loose px-2">
          ខ្ញុំឈ្មោះ ឈូក ដាវី។
          ខ្ញុំបានបញ្ជប់ការសិក្សាផ្នែកវិស្វកម្មសូហ្វវែរ៍នៅមហាវិទ្យាល័យ​សិល្បៈទំនាក់ទំនង
          និងបច្ចេកវិទ្យានៃសកល​វិទ្យាល័យឈៀងម៉ៃ ប្រទេស​ថៃ។
          សព្វថ្ងៃខ្ញុំជាវិស្វករ​សូហ្វវែរ៍​នៅក្រុមហ៊ុនមួយនៅក្នុងរាជធានីភ្នំពេញ។
          ខ្ញុំចូលចិត្តអានប្លក់អំពីប្រូក្រាមីង ការសិក្សាពីអវកាស
          និងបច្ចេកវិទ្យាថ្មីៗផ្សេងៗ។​
        </div>

        <div className="mb-6 leading-loose px-2">
          ការបង្កើតវែបសាយនេះ ខ្ញុំមានគោលបំណង​ចង់ជម្រុញ​ឲ្យ​វិស្វករ
          ​អ្នក​អភិវឌ្ឍន៍​កម្មវិធីខ្មែរ​ទាំង​អស់​គ្នា​ចូលចិត្ត​ការអាន
          ស្វែងយល់អំពី​បច្ចេក​វិទ្យា​ថ្មីៗ
          និង​សរសេរ​ចែក​រំលែក​ដើម្បី​ឲ្យ​ចំណេះដឹង​អំពីបច្ចេកវិទ្យា
          និងប្រូក្រាមីងឲ្យបាន​កាន់​តែ​ទូ​លំទូលាយ​នៅ​ក្នុង​ប្រទេស​យើង។
        </div>

        <div className="font-bold text-lg pb-4 px-2">ទំនាក់ទំនង</div>
        <div className="flex flex-col mb-8 px-2">
          <div className="mr-4 flex flex-row">
            <div className="mb-1 mr-2">អ៉ីម៉េល៖</div>
            <div className=" font-bold">yo@daseveny.com</div>
          </div>
        </div>

        <div className="font-bold text-lg pb-4 px-2">
          កុំភ្លេចតាមដានដាសេវេនី
        </div>
        <div className="flex mb-8 px-2">
          <a
            href="https://twitter.com/daseveny"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col justify-center items-center shadow-none hover:text-red-400 mr-4"
          >
            <svg
              className="fill-current text-blue-800 h-9"
              style={{ color: 'rgb(100, 174, 249)' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 910 1020"
            >
              <path d="M920 214c-24 36-55.333 68.667-94 98v24c0 86.667-20 170-60 250S664 733.667 580 789s-180.667 83-290 83c-106.667 0-203.333-28-290-84 9.333 1.333 24.667 2 46 2 88 0 166-26.667 234-80-41.333-1.333-78-14-110-38s-54-55.333-66-94c6.667 2.667 18 4 34 4 17.333 0 34-2 50-6-44-9.333-80-31.333-108-66s-42-74.667-42-120v-2c24 13.333 52 21.333 84 24-56-38.667-84-91.333-84-158 0-32 8.667-63.333 26-94 102.667 125.333 232.667 190.667 390 196-4-12-6-26-6-42 0-52 18.333-96.333 55-133s81.667-55 135-55c54.667 0 100 19.333 136 58 40-8 80-22.667 120-44-13.333 44-40.667 78.667-82 104 37.333-5.333 73.333-15.333 108-30" />
            </svg>
            <div className="text-center text-xs">ថ្វីតទ័រ</div>
          </a>

          <a
            href="https://t.me/daseveny"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col justify-center items-center shadow-none hover:text-red-400 mr-4"
          >
            <svg
              className="fill-current h-9 mr-2"
              viewBox="15 40 420 420"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                d="M254.6 76.5c49 .3 90.8 16.4 125.7 49.3 34.8 32.9 53.8 73.7 56.1 121.6 2.4 51.2-14 96-49.5 133.1-34.9 36.5-78 55.3-128.6 55.8-47.1.5-88.4-15.3-123-47.2-40.8-37.7-60.4-84.9-58.3-140.4 1.7-46.5 19.6-86.5 52.5-119.3 34.7-34.7 77.1-52 125.1-52.9z"
                fill="#0087cb"
              />
              <path
                d="M201.4 280.7c-9.8-3-19.7-6.1-29.5-9.1l-14.4-4.5c-1.8-.6-3.5-1.2-5-2.3-2.8-2.1-3-4.7-.6-7.3 1.6-1.8 3.6-3 5.8-3.9l17.4-6.9c40-15.4 80-30.9 120.1-46.3 12.7-4.9 25.3-9.8 38-14.7 1.7-.7 3.5-1.1 5.4-.8 3.8.6 6.7 3.7 7.1 7.7.2 2.2.1 4.4-.3 6.6-4.9 23-9.8 46.1-14.7 69.1-4 19-8.1 38-12.1 57.1l-2.7 12.9c-.4 2.1-1 4.1-2 6-2 3.7-5.2 5.1-9.3 4.3-2.5-.5-4.7-1.6-6.7-3.1-13.8-10.2-27.6-20.5-41.5-30.7-.6-.5-1.3-.9-1.9-1.3-2.4-.7-4.1-2.5-6-3.9-6.6-4.8-13.2-9.8-19.8-14.6-.5-.4-1-.8-1.4-1.3-.6-1 0-1.6.6-2.2 9-8 17.9-16.2 26.9-24.2 6.5-5.9 13.1-11.8 19.6-17.7 6.5-5.9 13-11.7 19.5-17.5 5.1-4.6 10.2-9.1 15.2-13.7l1.2-1.2c.3-.4.7-.8.5-1.3-.2-.6-.8-.6-1.3-.6-1.8-.2-3.3.5-4.8 1.4-5.5 3.3-10.8 6.8-16.2 10.2-16.4 10.3-32.9 20.7-49.3 31-11.3 7.1-22.6 14.2-33.9 21.4-1.3.7-2.2 2-3.9 1.4z"
                fill="#fcfdfe"
              />
              <path
                d="M201.4 280.7c2.7-1.1 5-2.9 7.5-4.4 7.2-4.5 14.4-9 21.6-13.6 5.1-3.2 10.1-6.5 15.2-9.7 8.4-5.3 16.9-10.6 25.4-15.9 5.9-3.7 11.8-7.5 17.8-11.3l15.6-9.9c1.7-1 3.4-1.6 5.5-1.4.6.1 1.2.1 1.4.7.3.6.1 1.2-.4 1.8-1.2 1.2-2.3 2.5-3.7 3.6-2.5 2-4.7 4.3-7.1 6.4-3.9 3.3-7.5 6.9-11.4 10.2-1.6 1.4-3.1 2.9-4.7 4.3-2.3 1.9-4.5 4.2-6.7 6.2-3.4 3.1-6.9 6.1-10.3 9.1-1.5 1.4-3.1 2.8-4.5 4.2-3.7 3.5-7.6 6.8-11.3 10.3-4.5 4.1-9.1 8.1-13.5 12.3-3.2 3-6.6 5.8-9.9 8.7-.5.4-.5.8-.2 1.3-.7 11.3-1.5 22.6-2.2 33.9-.2 3-.6 5.9-.5 8.9 0 .9 0 1.7-.7 2.4-.6.2-1.3.2-1.9.2-1.8-.1-3.1-1-3.6-2.7-.5-1.8-1.1-3.5-1.6-5.3-4.9-16.1-9.8-32.3-14.7-48.4-.5-.7-.8-1.3-1.1-1.9z"
                fill="#ccd9e9"
              />
              <path
                d="M224 338.7c-.3-3.9.4-7.8.5-11.6.2-4.2.6-8.4.8-12.6.3-4.3.6-8.6.9-12.8.1-2.2.3-4.4.3-6.5 0-.7.2-1.2.9-1.6 8.1 6 16.2 11.9 24.2 17.9.9.7 1.9 1.2 2.6 2.1-7.5 7.3-15.1 14.6-22.6 21.9-2 2-4.4 3.6-7.6 3.2z"
                fill="#b1c8db"
              />
            </svg>
            <div className="text-center text-xs">តេលេក្រាម</div>
          </a>

          <Link
            className="flex flex-col justify-center items-center shadow-none hover:text-red-400 mr-4"
            to="/rss.xml"
          >
            <svg
              className="fill-current h-9"
              style={{ color: 'rgb(234, 170, 0)' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 780 1050"
            >
              <path d="M0 120c209.333 0 388.333 74.333 537 223 148.667 148.667 223 327.667 223 537H642c0-177.333-62.667-328.333-188-453C328.667 302.333 177.333 240 0 240V120m0 238c145.333 0 269 51 371 153s153 225 153 369H406c0-110.667-39.667-205.667-119-285S112 476 0 476V358m114 296c30.667 0 57.333 11 80 33s34 49 34 81c0 30.667-11.333 57-34 79s-49.333 33-80 33-57.333-11-80-33-34-48.333-34-79c0-32 11.333-59 34-81s49.333-33 80-33" />
            </svg>
            <div className="text-center text-xs">ហ្វីដ RSS</div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default About
